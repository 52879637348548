import React, { FC, ReactElement } from 'react'
import { graphql } from 'gatsby'
import Microlink from '@microlink/react'
import Layout from '../components/layout'
import Container from '../components/container'

export const query = graphql`
  {
    db {
      # this causes a bug in gatsby currently...
      # posts(limit: 10, order_by: {date: asc}) {
      posts(limit: 10) {
        id
        date
        title
        service
        content
        link
      }
    }
  }
`

type PostType = {
  id: string
  date: string
  title: string
  service: 'instagram'
  content: string
  link?: string
}

interface UpdatesPageProps {
  data: {
    db: {
      posts: [PostType]
    }
  }
}

const UpdatesPage: FC<UpdatesPageProps> = ({
  data,
}: UpdatesPageProps): ReactElement => (
  <Layout
    title="Star Kitchen Latest Updates"
    canonical="/updates/"
    currentNav="Updates"
  >
    <Container>
      {data.db.posts.map((post) =>
        post.service ? (
          // https://microlink.io/docs/sdk/integrations/react/
          <Microlink
            key={post.id}
            url={post.link}
            size="large"
            media={['video', 'image']}
            autoPlay={false}
            controls={true}
            muted={false}
          />
        ) : (
          <article key={post.id}>
            <h2>{post.title}</h2>
            <div>{post.content}</div>
          </article>
        ),
      )}
    </Container>
  </Layout>
)

export default UpdatesPage
